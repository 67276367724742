import axios from 'axios';
import APIUtils from './APIUtils';
import { HealthProfessionField } from './HealthProfessionFieldUtils';
import LocaleUtils, { LanguageObject } from './LocaleUtils';
import SearchTermUtils, { SearchTerm } from './SearchTermUtils';

interface Tag {
  id: number;
  name: LanguageObject;
  domain: string;
  search_term: Partial<SearchTerm>;
}

interface TagSpecialty extends Tag {
  health_profession_field?: HealthProfessionField;
}

const tag = {
  generateDefault: (item?: Partial<Tag>): Partial<Tag> => {
    return {
      id: item?.id,
      name: LocaleUtils.langobj.generate(item?.name),
      domain: item?.domain,
      search_term: SearchTermUtils.searchTerm.generateDefault({
        ...item?.search_term,
        parent_type: 'tag',
        parent_id: item?.id,
      }),
    };
  },
};

const specialty = {
  generateDefault: (item?: Partial<TagSpecialty>): Partial<TagSpecialty> => {
    return {
      ...tag.generateDefault(item),
      health_profession_field: item?.health_profession_field,
    };
  },

  api: {
    list: async (params?: { health_profession_field_key: string }) => {
      const resp = await axios.get<Tag[]>('tag/specialty/list.php', {
        params: params,
      });
      return resp.data;
    },
    get: async (id: number) => {
      const resp = await axios.get<Tag>('tag/specialty/get.php', {
        params: { id: id },
      });
      return resp.data;
    },
    create: async (item: Partial<Tag>) => {
      const resp = await axios.post<Tag>('tag/specialty/create.php', {
        ...APIUtils.clean(item),
      });
      return resp.data;
    },
    update: async (item: Partial<Tag>) => {
      const resp = await axios.put<Tag>('tag/specialty/update.php', {
        ...APIUtils.clean(item),
      });
      return resp.data;
    },
    remove: async (id: number) => {
      const resp = await axios.delete<Tag>('tag/specialty/remove.php', {
        params: { id: id },
      });
      return resp.data;
    },
  },
};

export default { tag, specialty };
export { Tag, TagSpecialty };
