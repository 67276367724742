















































































































































































import HealthProfessionalUtils, {
  HealthProfessional,
  HealthProfessionalStatus,
} from '@/utils/HealthProfessionalUtils';
import HealthProfessionFieldUtils, {
  HealthProfessionField,
} from '@/utils/HealthProfessionFieldUtils';
import LanguageCapabilityUtils, {
  LanguageCapability,
} from '@/utils/LanguageCapabilityUtils';
import PointOfServiceUtils, {
  PointOfService,
} from '@/utils/PointOfServiceUtils';
import ProviderUtils, { Provider } from '@/utils/ProviderUtils';
import TagUtils, { TagSpecialty } from '@/utils/TagUtils';
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: Object as () => Partial<HealthProfessional>,
    },
    mode: {
      type: String as () => 'create' | 'edit',
      required: true,
    },
    'error-messages': {
      type: Object as () => { [key: string]: string[] },
      default: () => ({}),
    },
    readonly: {
      type: Boolean as () => boolean,
    },
  },

  computed: {
    internal: {
      get(): Partial<HealthProfessional> {
        return (
          this.value ||
          HealthProfessionalUtils.healthProfessional.generateDefault()
        );
      },
      set(val: Partial<HealthProfessional>) {
        this.$emit('input', val);
      },
    },

    status() {
      return Object.values(HealthProfessionalStatus);
    },

    language_service_keys() {
      return HealthProfessionalUtils.language_service_keys;
    },
    pointsSorted(): PointOfService[] | undefined {
      return this.points
        ?.slice()
        .sort((a: PointOfService, b: PointOfService) => {
          const localA = this.$langobj.parse(a.name);
          const localB = this.$langobj.parse(b.name);
          return localA && localB ? localA.localeCompare(localB) : 0;
        });
    },
  },

  data(): {
    providers?: Provider[];
    points?: PointOfService[];
    healthProfessionFields?: HealthProfessionField[];
    specialties?: TagSpecialty[];
    languageCapabilities?: LanguageCapability[];
  } {
    return {
      providers: undefined,
      points: undefined,
      healthProfessionFields: undefined,
      specialties: undefined,
      languageCapabilities: undefined,
    };
  },

  async mounted() {
    this.providers = await ProviderUtils.api.list();
    this.points = await PointOfServiceUtils.api.list();
    this.healthProfessionFields = await HealthProfessionFieldUtils.api.list({
      visible: true,
    });
    this.languageCapabilities =
      await LanguageCapabilityUtils.languageCapability.api.list({
        domain: 'health_professional',
      });
  },

  watch: {
    'internal.health_profession_field': {
      immediate: true,
      async handler() {
        this.specialties = undefined;
        if (this.internal.health_profession_field?.key) {
          this.specialties = await TagUtils.specialty.api.list({
            health_profession_field_key:
              this.internal.health_profession_field.key,
          });
        }
      },
    },
  },
});
