import axios from 'axios';
import APIUtils from './APIUtils';
import { HealthProfessionField } from './HealthProfessionFieldUtils';
import { PointOfService } from './PointOfServiceUtils';
import { Provider } from './ProviderUtils';
import { Tag } from './TagUtils';

enum HealthProfessionalStatus {
  ACTIVE = 'active',
  LEAVE = 'leave',
  INACTIVE = 'inactive',
  // OVERRIDDEN = 'overridden',
}

const language_service_keys = ['en', 'fr'];

// /title, first, middle, last, etc
interface HealthProfessional {
  id: number;
  name: {
    title?: string;
    first?: string;
    middle?: string;
    last?: string;
  };
  health_profession_field: HealthProfessionField;
  health_profession_name: string;
  division: string;
  job_title: string;
  email: string;
  phone: string;
  specialties: Partial<Tag>[];
  service_providers: Partial<Provider>[];
  points_of_service: Partial<PointOfService>[];
  rhp_profile_uri?: string;
  status: HealthProfessionalStatus;
  notes: string;
  language_capability_keys: string[];
  language_services: {
    [locale: string]: boolean;
  };
}

const generateLanguageServices = (language_services?: {
  [locale: string]: boolean;
}) => {
  const result: any = {};
  language_service_keys.forEach((locale) => {
    result[locale] = language_services ? language_services[locale] : false;
  });
  return result;
};

const healthProfessional = {
  generateDefault: (
    item?: Partial<HealthProfessional>
  ): Partial<HealthProfessional> => {
    return {
      id: item?.id,
      name: {
        title: item?.name?.title,
        first: item?.name?.first,
        middle: item?.name?.middle,
        last: item?.name?.last,
      },
      health_profession_field: item?.health_profession_field,
      health_profession_name: item?.health_profession_name,
      division: item?.division,
      job_title: item?.job_title,
      email: item?.email,
      phone: item?.phone,
      specialties: item?.specialties || [],
      service_providers: item?.service_providers || [],
      points_of_service: item?.points_of_service || [],
      rhp_profile_uri: item?.rhp_profile_uri,
      status: item?.status || HealthProfessionalStatus.ACTIVE,
      notes: item?.notes,
      language_capability_keys: item?.language_capability_keys || [],
      language_services: generateLanguageServices(item?.language_services),
    };
  },

  formatName: (name: Partial<HealthProfessional['name']>) => {
    return `${name.last || ''}, ${name.first || ''} ${
      name.middle || ''
    } `.trim();
  },
};

const api = {
  list: async (params?: { point_of_service_id?: number }) => {
    const resp = await axios.get<HealthProfessional[]>(
      'healthProfessional/list.php',
      { params: params }
    );
    return resp.data;
  },
  get: async (id: number) => {
    const resp = await axios.get<HealthProfessional>(
      'healthProfessional/get.php',
      {
        params: { id: id },
      }
    );
    return resp.data;
  },
  create: async (item: Partial<HealthProfessional>) => {
    const resp = await axios.post<HealthProfessional>(
      'healthProfessional/create.php',
      {
        ...APIUtils.clean(item),
      }
    );
    return resp.data;
  },
  update: async (item: Partial<HealthProfessional>) => {
    const resp = await axios.put<HealthProfessional>(
      'healthProfessional/update.php',
      {
        ...APIUtils.clean(item),
      }
    );
    return resp.data;
  },
  remove: async (id: number) => {
    const resp = await axios.delete<HealthProfessional>(
      'healthProfessional/remove.php',
      {
        params: { id: id },
      }
    );
    return resp.data;
  },
};

export default { api, healthProfessional, language_service_keys };
export { HealthProfessional, HealthProfessionalStatus };
