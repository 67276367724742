import axios from 'axios';
import APIUtils from './APIUtils';
import LocaleUtils, { LanguageObject } from './LocaleUtils';

interface HealthProfessionField {
  id: number;
  key: string;
  name: LanguageObject;
  deployment: boolean;
}

const healthProfessionField = {
  generateDefault: (
    item?: Partial<HealthProfessionField>
  ): Partial<HealthProfessionField> => {
    return {
      id: item?.id,
      key: item?.key,
      name: LocaleUtils.langobj.generate(item?.name),
      deployment: item?.deployment,
    };
  },
};

const api = {
  list: async (params?: { visible: boolean }) => {
    const resp = await axios.get<HealthProfessionField[]>(
      'healthProfessionField/list.php',
      { params: params }
    );
    return resp.data;
  },
  get: async (id: number) => {
    const resp = await axios.get<HealthProfessionField>(
      'healthProfessionField/get.php',
      {
        params: { id: id },
      }
    );
    return resp.data;
  },
  create: async (item: Partial<HealthProfessionField>) => {
    const resp = await axios.post<HealthProfessionField>(
      'healthProfessionField/create.php',
      {
        ...APIUtils.clean(item),
      }
    );
    return resp.data;
  },
  update: async (item: Partial<HealthProfessionField>) => {
    const resp = await axios.put<HealthProfessionField>(
      'healthProfessionField/update.php',
      {
        ...APIUtils.clean(item),
      }
    );
    return resp.data;
  },
  remove: async (id: number) => {
    const resp = await axios.delete<HealthProfessionField>(
      'healthProfessionField/remove.php',
      {
        params: { id: id },
      }
    );
    return resp.data;
  },
};

export default { api, healthProfessionField };
export { HealthProfessionField };
